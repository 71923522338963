import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/paginas/tips.css';

function InstagramPost({ url, texto }) {
    
  return (
    <div>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <Card className="border-0 rounded-0 bg-gray">
            <Card.Body>
              <Row>
                <Col md="12">
                  <blockquote
                    className="instagram-media"
                    data-instgrm-permalink={url}
                    data-instgrm-version="12"
                    style={{
                      background: '#FFF',
                      border: '0',
                      borderRadius: '3px',
                      boxShadow: '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)',
                      margin: '1px',
                      maxWidth: '493px',
                      minWidth: '322px !important',
                      padding: '0',
                      width: '99.375%',
                      width: '-webkit-calc(100% - 2px)',
                      width: 'calc(98% - 1px)',
                    }}
                  >
                  </blockquote>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default InstagramPost;

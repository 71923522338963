import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CarouselInicio from './../carousel/carousel';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Animated from '../animated/animated';
import CarouselRewards from '../carousel/carouselRewards';

function Inicio() {
    const cardsData = [
        {
            title: "Ortodoncia",
            description: "Con la ortodoncia nos encargamos de corregir las malposiciones dentales, así como la estética y función bucodental; buscando armonía en la mordida del paciente, a través de la colocación de brackets o bien con alineadores dentales de manera estética, de acuerdo al plan de tratamiento de cada paciente.",
        },
        {
            title: "Ortopedia dentofacial",
            description: "A través de la ortopedia con el uso de aparatos corregiremos en etapas tempranas las discrepancias en la proporción, tamaño y posición de los huesos de la cara y eliminación de malos hábitos que perjudiquen el correcto desarrollo esqueletal, favoreciendo así la guía de erupción dental en los niños.",
        },
    ];
    return (
        <>
            <CarouselInicio />
            <br />
            <Row>
                <Col >
                    <Animated>
                        <Card className="border-0 bg-gray" >
                            <Card.Body style={{ textAlign: 'center', fontSize: '30px', color: '#cc9999c2' }}>
                                <Card.Text className='mt-lg-5 mb-3'>
                                    <strong>SERVICIOS</strong>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Animated>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                {cardsData.map((card, index) => (
                    <Col key={index} md={{ span: 6, offset: 0 }} xs={{ span: 10, offset: 0}}>
                        <Animated useScale={true} mh={true}>
                            <Card className="border-0 bg-white" style={{ marginBottom: "15px", height: "90%"}}>
                                <Card.Body >
                                    <Card.Title style={{ textAlign: 'justify' }}>
                                        <strong className='txt-xs'>{card.title}</strong>
                                    </Card.Title>
                                    <Card.Text className='line-pink' style={{ textAlign: 'justify' }}>
                                        {card.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Animated>
                    </Col>
                ))}
            </Row>
            
            <Row>
                <Col >
                    <Animated>
                        <Card className="border-0 bg-gray" >
                            <Card.Body style={{ textAlign: 'center', fontSize: '30px', color: '#cc9999c2' }}>
                                <Card.Text className='mt-lg-5 mb-3'>
                                    <strong>OPINIONES</strong>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Animated>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <CarouselRewards></CarouselRewards>
            </Row>
            <div>...</div>
            <Row>
                <Col >
                    <Card className="border-0 bg-gray" style={{ marginBottom: "15px" }}>
                        <Card.Body style={{ textAlign: 'center' }}>
                            <Row>
                                <Col >
                                    <Card.Text >
                                    En nuestro consultorio, nos comprometemos a brindarte una atención personalizada y efectiva, para que puedas lograr una sonrisa saludable y radiante. ¡Contáctanos hoy mismo para comenzar tu viaje hacia una mejor salud dental!
                                    </Card.Text>
                                    <Card.Text ><Link to="/contacto">
                                            <Button className='btn-pink' >¡CONTACTANOS!</Button>
                                        </Link>
                                    </Card.Text>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default Inicio;

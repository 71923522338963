import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/general/SocialMedia.css';
import Col from 'react-bootstrap/Col';
import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import { IconContext } from "react-icons";

function SocialMedia({woffset=10, wspan=1, className="social"}) {
    return (
        <Col className={className} md={{ span: wspan, offset: woffset }}>
            <a href="https://www.facebook.com/profile.php?id=100057854635180" target="_blank" rel="noreferrer">
                <IconContext.Provider value={{ color: 'white', size: '20px' }} >
                    <FaFacebook />
                </IconContext.Provider>
            </a>
            <a href="https://www.instagram.com/ortodoncia.almavelasco/" target="_blank" rel="noreferrer">
                <IconContext.Provider value={{ color: 'white', size: '20px' }} >
                    <FaInstagram />
                </IconContext.Provider>
            </a>
            <a href="https://wa.me/522281250185?text=Hola,%20quisiera%20más%20información%20por%20favor." target="_blank" rel="noreferrer">
                <IconContext.Provider value={{ color: 'white', size: '20px' }} >
                    <FaWhatsapp />
                </IconContext.Provider>
            </a>
        </Col>
    );
}

export default SocialMedia;
import '../../styles/footer/Footer.css'
import React from 'react';
import Logo from '../../media/Logo-AV_SFB.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SocialMedia from '../general/SocialMedia';

function Footer() {
    const year = new Date().getFullYear();
    return (
        <>
        <br/>
         <footer style={{ textAlign: 'center', color: "white" }} className='bg-pink'>
            <Row style={{alignItems:"center"}}>
                <Col xs="12" lg="3">
                    <img src={Logo} className="App-logo" alt="logo" />
                </Col>
                <Col xs="8" lg="6">
                    <h6 style={{margin:"0"}}> Cédula profesional: 12281596</h6>
                </Col>
                <Col xs lg="3">
                    <SocialMedia woffset="0" wspan="" className='social-footer'/>
                </Col>
                <Col xs="12" lg="12" className='mb-3'>
                    <hr/>
                    <h6 style={{margin:"0"}}> © Derechos reservados Ortodoncia Velasco {year}</h6>
                </Col>
            </Row>

        </footer>
        </>
       
    );
}

export default Footer;



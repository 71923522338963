import './App.css';
import React, { useState, useEffect } from "react";
import Header from './components/header/Header';
import MenuBurger from './components/header/MenuBurger';
import Footer from './components/footer/Footer';
import Inicio from './components/paginas/Inicio';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AcercaDe from './components/paginas/Acerca_de';
import Estudios from './components/paginas/Estudios';
import Ortodoncia from './components/paginas/Ortodoncia';
import Testimonios from './components/paginas/testimonios';
import Tips from './components/paginas/Tips';
import Avances from './components/paginas/Avances';
import Contacto from './components/paginas/Contacto';
import Logo from './media/Logocorto.png';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {

  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {

  }, []);

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Header />
          <MenuBurger isOpen={menuOpen} />
          <div className='mobile-header'>
                <img src={Logo} className="App-logo burguer" alt="logo" />
                </div>
        </header>
        <div className='main'>
          <Row className="justify-content-md-center">
            <Col xs lg="10" md="10" xl="8" >
              <body>
                
                <Routes>
                  <Route path="/" element={<Inicio />} />
                  <Route path="/acerca-de" element={<AcercaDe title="ACERCA DE" />} />
                  <Route path="/ortodoncia" element={<Ortodoncia title="Ortodoncia" />} />
                  <Route path="/estudios" element={<Estudios title="Estudios" />} />
                  <Route path='/Testimonios' element={<Testimonios title='Testimonios' />} />
                  <Route path='/tips' element={<Tips title='Tips' />} />
                  <Route path='/avance-de-casos' element={<Avances title='Avances' />} />
                  <Route path='/contacto' element={<Contacto title='Contacto' />} />
                </Routes>
              </body>
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    </Router>

  );
}

export default App;

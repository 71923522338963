import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link, useLocation } from 'react-router-dom';

import '../../styles/header/MenuBurger.css';

const MenuBurger = () => {
  const location = useLocation();


  const isActive = (pathname) => {
    return location.pathname === pathname ? 'active' : '';
  };

  return (
    <Menu>
      <Link to="/" className={isActive('/') ? 'menu-item active' : 'menu-item'}>
        INICIO
      </Link>
      <Link to="/acerca-de" className={isActive('/acerca-de') ? 'menu-item active' : 'menu-item'}>
        ACERCA DE
      </Link>
      <Link to="/ortodoncia" className={isActive('/ortodoncia') ? 'menu-item active' : 'menu-item'}>
        ¿QUÉ ES LA ORTODONCIA?
      </Link>
      <Link to="/estudios" className={isActive('/estudios') ? 'menu-item active' : 'menu-item'}>
        ESTUDIOS
      </Link>
      <Link to="/avance-de-casos" className={isActive('/avance-de-casos') ? 'menu-item active' : 'menu-item'}>
        CASOS
      </Link>
      <Link to="/testimonios" className={isActive('/testimonios') ? 'menu-item active' : 'menu-item'}>
        TESTIMONIOS
      </Link>
      <Link to="/tips" className={isActive('/tips') ? 'menu-item active' : 'menu-item'}>
        TIPS
      </Link>
      <Link to="/contacto" className={isActive('/contacto') ? 'menu-item active' : 'menu-item'}>
        CONTACTO
      </Link>
    </Menu>
  );
};

export default MenuBurger;

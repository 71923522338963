import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/paginas/Acerca_de.css';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from '../general/Loader';
import Animated from '../animated/animated';
import PGDL7699 from '../../media/img/PGDL7699_.webp';
import PGDL7346 from '../../media/img/PGDL7346_.webp';
import PGDL0604 from '../../media/img/PGDL0604_.webp';
import PGDL7762 from '../../media/img/PGDL7762_.webp';

function AcercaDe() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fakeLoading = setTimeout(() => {
            setLoading(false); // Simulación de carga completada después de 2 segundos
        }, 2500);

        return () => {
            clearTimeout(fakeLoading); // Limpia el temporizador al desmontar el componente
        };
    }, []);

    return (
        <div className='container'>
            <Row >

                <Col md="12">
                    <Card className="border-0 bg-pink">
                        <Card.Body style={{ textAlign: 'justify' }}>
                            <Row>
                                <Col lg="4" className='white'>
                                    <Animated>
                                        <Card.Title>ACERCA DE</Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted white">Ortodoncia Alma Velasco</Card.Subtitle>
                                    </Animated>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <img src={PGDL7699} alt="Alma velasco" className='img-float-left-lg rounded-1' />
                                </Col>
                                <Col md="8">
                                    <Animated>
                                        <Card.Text >
                                            ¡Bienvenidos a mi página web! Mi nombre es Alma Angélica  Velasco Bandala, ortodoncista  <strong>originaria de la ciudad de Xalapa Veracruz, </strong>
                                            apasionada y comprometida con brindar sonrisas saludables y hermosas en esta ciudad capital, así como de sus alrededores y otros
                                            lugares que nos visiten.
                                        </Card.Text>
                                    </Animated>
                                </Col>
                                <Col md={{ span: 8, offset: 4 }}>
                                    <Animated>
                                        <Card.Text >
                                            <br />
                                            Mi historia en la ortodoncia comienza con un profundo interés en transformar vidas de cada uno de los pacientes que buscan encontrar esa
                                            sonrisa que tanto anhelan. Día a día, agradezco la oportunidad de trabajar con pacientes de diversas edades y ser parte de su
                                            transformación dental. Mi enfoque no se trata solo de corregir la alineación dental, sino también de brindar
                                            <strong> confianza y seguridad </strong>a mis pacientes a través de cada tratamiento.
                                        </Card.Text>
                                    </Animated>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="12">
                    <Card className="border-0  bg-gray">
                        <Card.Body style={{ textAlign: 'justify' }}>
                            <Row>
                                <Col lg={{ span: 12, offset: 0 }} xl={{ span: 8, offset: 4 }} md={{ span: 12, offset: 0 }}>
                                    <Animated>
                                        <Card.Text >
                                            Mi formación académica y experiencia en la ortodoncia me han proporcionado una base sólida para ofrecer tratamientos de alta
                                            calidad y vanguardia. Creo en la importancia de estar actualizada en las últimas tecnologías y técnicas en este campo, y siempre
                                            busco oportunidades para mejorar y aprender para beneficio de mis pacientes, así como de mi propia preparación profesional para
                                            poder tener la capacidad de resolver cada caso.
                                        </Card.Text>
                                    </Animated>
                                </Col>
                                <Col lg={{ span: 12 }} xl={{ span: 12 }} >
                                    <Animated>
                                        <Card.Text >
                                            <br />
                                            Mi compromiso con la atención al paciente se basa en la <strong>empatía y la comunicación</strong> abierta creando lazos con cada
                                            uno de ustedes, ya que cada paciente es único y tiene necesidades individuales. Estoy aquí para escuchar tus objetivos y
                                            preocupaciones, y trabajar juntos para crear un plan de tratamiento personalizado que se adapte a tus necesidades.
                                        </Card.Text>
                                    </Animated>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="12">
                    <Card className="border-0  bg-gray">
                        <Card.Body style={{ textAlign: 'justify', padding: '0.8rem' }}>
                            <Row style={{ flexDirection: "column-reverse" }}>
                                <Col md={{ span: 8 }} className='bg-white'>
                                    <Animated>
                                        <Card.Text >
                                            En <strong className='pink' style={{ fontSize: "18px" }}>Ortodoncia Alma Velasco</strong>, valoramos la ética profesional y la excelencia en cada paso del proceso, ofreciéndote diferentes opciones y alternativas de tratamiento desde aparatos ortopédicos, brackets convencionales, brackets de autoligado y alineadores invisibles.
                                            <br />
                                            <br />
                                            Estoy emocionada por la oportunidad de ser tu ortodoncista y acompañarte en tu viaje hacia una sonrisa más hermosa. Si tienes preguntas o estás listo para comenzar, no dudes en ponerte en contacto a través de los enlaces proporcionados. ¡Espero conocerte pronto y ser parte de tu transformación dental!
                                            <br />
                                            <br />
                                            <strong>Recuerda ¡Sonríe te queda hermoso!</strong>
                                            <br />
                                            -Alma Velasco
                                        </Card.Text>
                                    </Animated>
                                </Col>
                                <Col md="4">
                                    <img src={PGDL7346} alt="Alma velasco" className='img-float-right rounded-1' />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <br />
                </Col>
                <Col md="12">
                    <Card className="border-0   bg-pink">
                        <Card.Body style={{ textAlign: 'justify' }}>
                            <Row>
                                <Col lg={{ span: 8, offset: 4 }}>
                                    <Animated>
                                        <Card.Text >
                                            <Card.Title className='txt-right white'>UN POCO SOBRE MI</Card.Title>
                                            <Card.Subtitle className="mb-2 txt-right white">Alma Velasco</Card.Subtitle>
                                        </Card.Text>
                                    </Animated>
                                </Col>
                                <Col md="4">
                                    <img src={PGDL0604} alt="Alma velasco" className='img-float-left rounded-1' />
                                </Col>
                                <Col lg="8">
                                    <Animated>
                                        <Card.Text >
                                            <br />
                                            Fuera del campo de la ortodoncia creo en la importancia de encontrar un equilibrio entre el <strong>trabajo, la familia y la vida social</strong>,
                                            disfrutando así de mi vida personal.
                                            <br /><br />
                                            Soy una mujer apasionada en todo lo que hace en cada momento, <strong>agradecida siempre</strong> por la oportunidad que tengo en cada despertar.
                                            <br /><br />
                                        </Card.Text>
                                    </Animated>
                                </Col>
                            </Row>
                            <br />
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="12">
                    <Card className="border-0 bg-gray">
                        <Card.Body style={{ textAlign: 'justify' }}>
                            <Row>
                                <Col md={{ span: 8 }}>
                                    <Animated>
                                        <Card.Text >
                                            <br />
                                            Mi recorrido en el trayecto de esta vida para lograr consolidarme cómo lo que ahora soy ha sido arduo, sin embargo, ahora agradezco y disfruto cada gota de sudor, desvelo, sacrificio y esfuerzo,  sin dejar de lado el apoyo de las personas que me aman y han demostrado su amor incondicional siempre.
                                            <br />
                                            <br />
                                            Gracias por ser parte ahora de esta nueva historia en la que todo empieza buscando una sonrisa hermosa y termina creando
                                            lazos de <strong>empatía y amistad entre tu y yo</strong>.
                                        </Card.Text>
                                    </Animated>
                                    <br /><br /><br /><br />
                                </Col>
                                <Col md={{ span: 4 }}>
                                    <img src={PGDL7762} alt="Alma velasco" className='img-float-right-lg rounded-1' />
                                </Col>

                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {loading ? <Loader className="loader-lg" /> : null}
        </div>
    );
}

export default AcercaDe;
import React, { useState, useEffect, useRef } from 'react';
import './gridImages.css';
import ModalCase from '../modal/ModalCase';
import imagesData from './case';
import Animated from '../animated/animated';

const GridImages = ({ activeCategory }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [page, setPage] = useState(1);
    const imagesPerPage = 12;
    const [displayedImages, setDisplayedImages] = useState([]);
    const loadingRef = useRef(null);

    const openModal = (image) => {
        setSelectedImage(image);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    const loadMoreImages = () => {
        setPage((prevPage) => prevPage + 1);
    };

    useEffect(() => {
        setDisplayedImages([]);
        setPage(1);
    }, [activeCategory, imagesPerPage]);

    useEffect(() => {
        const startIndex = (page - 1) * imagesPerPage;
        const endIndex = startIndex + imagesPerPage;
    
        const filteredImages = imagesData.filter((image) => {
            if (activeCategory === 'todos') {
                return true;
            } else {
                return image.type.toLowerCase() === activeCategory.toLowerCase();
            }
        });
    
        const newImages = filteredImages.slice(startIndex, endIndex);
    
        // Si estamos en la primera página, restablecer la lista de imágenes mostradas
        if (page === 1) {
            setDisplayedImages(newImages);
        } else {
            setDisplayedImages((prevImages) => [...prevImages, ...newImages]);
        }
    }, [page, activeCategory, imagesPerPage]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    loadMoreImages();
                }
            },
            { threshold: 1 }
        );

        if (loadingRef.current) {
            observer.observe(loadingRef.current);
        }

        return () => {
            if (loadingRef.current) {
                observer.unobserve(loadingRef.current);
            }
        };
    }, [loadingRef, activeCategory]);

    return (
        <>
            {displayedImages.map((image, index) => (
                <Animated key={index}>
                    <div className='img-container' onClick={() => openModal(image)}>
                        <img src={image.src} alt={image.alt} className='rounded-1' />
                    </div>
                </Animated>
            ))}
            <div ref={loadingRef}></div>
            {selectedImage !== null && (
                <ModalCase show={true} setShow={closeModal} selectedImage={selectedImage}></ModalCase>
            )}
        </>
    );
};

export default GridImages;

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/paginas/avances.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import GridImages from '../gridImages/GridImages';

function Avances() {
    const [activeCategory, setActiveCategory] = useState('todos');

    const handleCategoryChange = (category) => {
        setActiveCategory(category);
    };

    const isActive = (category) => {
        return activeCategory.toLowerCase() === category.toLowerCase() ? 'active' : '';
    };

    return (
        <Row>
            <Col md={{ span: 12, offset: 0}} xs={{ span: 10, offset: 1}} style={{ textAlign: 'center', color: "" }} className='bg-white mb-4'>
                <Card.Subtitle className="mb-1 text-muted">
                En esta sección, te presentamos una recopilación detallada de <strong className='pink'>algunos de los casos</strong> en los que hemos dedicado nuestro esfuerzo y experiencia.
                </Card.Subtitle>
            </Col>
            <Col md="12" className='menu-casos'>
                <header>
                    <nav>
                        <ul>
                            <li className={isActive('todos')} onClick={() => handleCategoryChange('todos')}>Todos</li>
                            <li className={isActive('avance')} onClick={() => handleCategoryChange('avance')}>Avances</li>
                            <li className={isActive('finalizado')} onClick={() => handleCategoryChange('finalizado')}>Finalizados</li>
                        </ul>
                        <hr style={{ border: "none", borderTop: "1px solid #ccc", margin: "1.5% 0% 0% 0%", width: "100%" }} />
                    </nav>
                </header>
            </Col>
            <Col md="12" className='images-container'>
                <GridImages activeCategory={activeCategory} />
            </Col>
        </Row>
    );
}

export default Avances;
